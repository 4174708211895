<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    lazy
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general v-if="options.general" :general-data="options.general" :user-data="userData" />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- manage mfa tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="AlertOctagonIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Manage MFA</span>
      </template>

      <account-setting-m-f-a />
    </b-tab>
    <!--/ manage mfa tab -->

    <!-- info -->
    <b-tab v-if="false">
      <!-- title -->
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Information</span>
      </template>

      <account-setting-information v-if="options.info" :information-data="options.info" />
    </b-tab>

    <!-- social links -->
    <b-tab v-if="true">
      <!-- title -->
      <template #title>
        <feather-icon icon="LinkIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social v-if="options.social" :user-id="userData.id" :social-data.sync="userData.metadata" />
    </b-tab>

    <!-- notification -->
    <b-tab v-if="false">
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification v-if="options.notification" :notification-data="options.notification" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import AccountSettingGeneral from './AccountSettingGeneral.vue';
import AccountSettingPassword from './AccountSettingPassword.vue';
import AccountSettingMFA from './AccountSettingMFA.vue';
import AccountSettingInformation from './AccountSettingInformation.vue';
import AccountSettingSocial from './AccountSettingSocial.vue';
import AccountSettingNotification from './AccountSettingNotification.vue';

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingMFA,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      options: {},
    };
  },
  created() {
    let settings = {
      general: this.userData,
      info: {
        bio: '',
        dob: null,
        country: 'USA',
        website: '',
        phone: 6562542568,
      },
      social: {
        socialLinks: {
          twitter: 'https://www.twitter.com',
          facebook: '',
          google: '',
          linkedIn: 'https://www.linkedin.com',
          instagram: '',
          quora: '',
        },
        connections: {
          twitter: {
            profileImg: '/img/11-small.4ade0856.png',
            id: 'johndoe',
          },
          google: {
            profileImg: '/img/3-small.33d29f56.png',
            id: 'luraweber',
          },
          facebook: {},
          github: {},
        },
      },
      notification: {
        commentOnArticle: true,
        AnswerOnForm: true,
        followMe: false,
        newAnnouncements: true,
        productUpdates: true,
        blogDigest: false,
      },
    };

    this.options = settings;
  },
};
</script>
