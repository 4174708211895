<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-avatar
          ref="previewEl"
          :src="optionsLocal.avatar"
          :text="avatarText(optionsLocal.name)"
          :variant="`light-${resolveUserRoleVariant(optionsLocal.role)}`"
          size="90px"
          rounded
        />

        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          :disabled="true"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm" class="mb-75 mr-75">
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input v-model="optionsLocal.username" placeholder="Username" name="username" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Name" label-for="account-name">
            <b-form-input v-model="optionsLocal.name" name="name" placeholder="Name" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input v-model="optionsLocal.email" name="email" placeholder="Email" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Phone # (match format)" label-for="account-phoneNumber">
            <b-form-input v-model="optionsLocal.phoneNumber" name="phoneNumber" placeholder="+17035543827" />
          </b-form-group>
        </b-col>
        <b-col sm="6" v-if="false">
          <b-form-group label="Company" label-for="account-company">
            <b-form-input v-model="optionsLocal.company" name="company" placeholder="Company name" />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col cols="12" class="mt-75" v-if="false && !this.userData.isEmailVerified">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">Your email is not confirmed. Please check your inbox.</h4>
            <div class="alert-body">
              <b-link @click="requestEmailConfirmation" class="alert-link"> Resend confirmation </b-link>
            </div>
          </b-alert>
        </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="processing"
            @click="saveChanges"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import useUsersList from '@/views/apps/user/users-list/useUsersList';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BAvatar,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      processing: false,
    };
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
    requestEmailConfirmation() {},
    saveChanges() {
      this.processing = true;

      this.$http
        .patch(`users/${this.userData.id}`, {
          username: this.optionsLocal.username,
          name: this.optionsLocal.name,
          email: this.optionsLocal.email,
          phoneNumber: this.optionsLocal.phoneNumber,
        })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem('userData', JSON.stringify(response.data));

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `User Updated`,
                icon: 'UserIcon',
                variant: 'success',
                text: `Your settings have been updated. Please wait...`,
              },
            });

            return setTimeout(() => location.reload(), 1500);
          }

          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;

          if (error.response) {
            const { data } = error.response;

            if (data.message) {
              return this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to Update User',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: data.message,
                },
              });
            }
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to Update User',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'An unknown error has occurred.',
            },
          });
        });
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
    const { resolveUserRoleVariant } = useUsersList();

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      avatarText,
      resolveUserRoleVariant,
    };
  },
};
</script>
